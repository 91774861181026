import React, { memo } from 'react'

import CopyText from '../../shared/components/CopyText'
import InfoTable from './components/InfoTable'

const Info = () => {
    return (
        <div className='main-content-container container grid-lg'>

            <h5>Een correct WOZ CSV-bestand voldoet aan de volgende aspecten</h5>
            <p>
                De titelrij / veldnamenrij is optioneel.
                Indien aanwezig, moet deze de exacte veldnamen en volgorde bevatten zoals hieronder is opgenomen (inclusief hoofdletters, spaties, etc):
            </p>

            <CopyText
                heading=''
                scroll
                show
                text='Onderdeel;Eenheidscode;Postcode NL;Huisnummer NL;Huisletter NL;Huisnummertoevoeging;Aantal primo verslagjaar;Aantal ultimo verslagjaar;Tak primo verslagjaar;Tak ultimo verslagjaar;Eenheidsoort;Soort woonruimte;Is de woonruimte zelfstandig?;Lift aanwezig?;Mutatie verslagjaar;Nettohuur per maand ultimo verslagjaar;Aantal WWS punten ultimo verslagjaar;Energielabel;Bouwjaar;Renovatiejaar;m2;WOZ-waarde verslagjaar;Marktwaarde ultimo verslagjaar;Onderpand WSW inzet verslagjaar;Naam zekerheidsnemer;Type zekerheid;Eigendomspercentage'
            />

            <p>Elke rij bevat exact 27 kolommen.</p>

            <p>De kolommen moeten gescheiden zijn met een <code>;</code> (puntkomma) als scheidingsteken:</p>
            <code>12;ja;nee;2,45</code>

            <p>Als decimaalteken in een getal moet een <code>,</code> (komma) gebruikt worden:</p>
            <code>12,34;45,63;2,00;2,45</code>

            <p>Tevens mag bij een getalnotatie geen teken gebruikt worden voor duizendtallen:</p>
            <span className='strike-through'><code>1.000,00</code></span>

            <p>
                Wanneer tekst 'escaped' moet worden (bijvoorbeeld in het geval dat een <code>;</code> in de tekst staat,
                maar niet een scheidingsteken is),
                dan moet een <code>"</code> (dubbel aanhalingsteken) gebruikt worden:
            </p>
            <code>55,63;"Deze tekst ; is 1 kolom";ja</code>

            <p>
                Wanneer de tekst die 'escaped' moet worden zelf een aanhalingsteken bevat,
                moet dit aanhalingsteken zelf escaped worden met een extra aanhalingsteken:
            </p>
            <code>55,63;"Deze ""tekst"" ; is 1 kolom";ja</code>

            <p>Hieronder is per kolom aangegeven waar het CSV-bestand aan moet voldoen:</p>

            <InfoTable />
        </div>
    )
}

export default memo(Info)
