import propTypes from 'prop-types'
import React, { memo } from 'react'

type PropType = {
    show: boolean,
}
/**
 * Show the error messages resulting from an incorrectly formed CSV
 */
const ErrorMessages: React.FC<PropType> = ({ show }) => {
    ErrorMessages.propTypes = {
        show: propTypes.bool.isRequired
    }

    return show
        ? (
            <div>
                <h4>Er zijn problemen met de validatie!</h4>
            </div>
        )
        : null
}

export default memo(ErrorMessages)
