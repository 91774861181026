import propTypes from 'prop-types'
import React, {memo} from 'react'
import {isNumeric} from "../../../shared/utils/utils";

type PropType = {
    show: boolean,
    kvkNumber: string
    setKvkNumber: (value: string) => void,
    status: string,
    setStatus: (value: string) => void,
    labelCls: string,
    inputCls: string,
    helpCls: string
}

/**
 * Simple input component for the user to input their KVK number
 */
const KvkInput: React.FC<PropType> = ({ show, kvkNumber, setKvkNumber, status, setStatus,
                                          labelCls='', inputCls='', helpCls = '' }) => {

    KvkInput.propTypes = {
        show: propTypes.bool.isRequired,
        kvkNumber: propTypes.string.isRequired,
        setKvkNumber: propTypes.func.isRequired,
        status: propTypes.string.isRequired,
        setStatus: propTypes.func.isRequired
    }

    const verifyKVKLength = () => {
        if (kvkNumber.length !== 8 && kvkNumber.length !== 0) {
            setStatus('is-error')
        }
    }

    const verifyKVKForm = (kvkNumber: string) => {
        if ((kvkNumber.slice(0, 2) === '00') || kvkNumber.length > 8 || (!isNumeric(kvkNumber) && kvkNumber.length !== 0)) {
            // simply do nothing (do not 'set' the value):
            // it is impossible to get kvk number in an invalid state (except for clearing the field)
        } else if (kvkNumber.length === 8) {
            setKvkNumber(kvkNumber)
            setStatus('is-success')
        } else {
            // numeric, to short, clear red lining (user still typing)
            setKvkNumber(kvkNumber)
            setStatus('')
        }
    }

    const helpMessage = status === 'is-error'
        ? <p className='is-error'>Registratienummer bij de Kamer van Koophandel heeft een incorrect formaat.</p>
        : null
    return show
        ? (
            <>
                <div className={labelCls}>
                    <label
                        className='form-label'
                        htmlFor='kvk-number-input'
                    >
                        Registratienummer bij de Kamer van Koophandel
                    </label>
                </div>
                <div className={inputCls}>
                    <input
                        className={status + ' form-input'}
                        type='tel'
                        pattern='[0-9]{8}'
                        id='kvk-number-input'
                        name='kvkNummer'
                        required={true}
                        value={kvkNumber}
                        placeholder='12345678'
                        onChange={e => verifyKVKForm(e.target.value)}
                        onBlur={verifyKVKLength}/>
                </div>
                <div className={helpCls}>
                    {helpMessage}
                </div>
            </>
        )
        : null
}

export default memo(KvkInput)
