import propTypes from 'prop-types'
import React, {memo} from 'react'

type PropType = {
    fileExtension: string,
    fileType: string,
    handleProgress: (e: React.ChangeEvent<HTMLInputElement>) => void,
    show: boolean,
    successFailure: string
}

const FileUpload: React.FC<PropType> = ({
                                            fileExtension,
                                            fileType,
                                            handleProgress,
                                            show,
                                            successFailure
                                        }) => {

    FileUpload.propTypes = { // do we still need this?
        /**
         * String to specify the filetype in order to filter the upload options for the user
         */
        fileExtension: propTypes.string.isRequired,
        /**
         * String to specify the name of the filetype. This will be used for the label and id of the element
         */
        fileType: propTypes.string.isRequired,
        /**
         * Function that specifies what the filetype is that is used with the upload id
         */
        handleProgress: propTypes.func.isRequired,
        show: propTypes.bool.isRequired,
        successFailure: propTypes.string.isRequired
    }
    const errorMessage = successFailure === 'is-error'
        ? <p className='is-error'>Bestand mag maximaal 20mb groot zijn.</p>
        : null
    return show
        ? (
            <div className='form-container form-group'>
                <label
                    htmlFor={fileType + '-upload'}
                    className='form-label'
                >
                    {fileType} uploaden
                </label>
                <input
                    className={successFailure + ' form-input'}
                    type='file'
                    id={fileType + '-upload'}
                    onChange={handleProgress}
                    accept={fileExtension}
                />
                {errorMessage}
            </div>
        )
        : null
}

export default memo(FileUpload)
