import React, { memo } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    const branch = process.env.REACT_APP_BRANCH
    const date = process.env.REACT_APP_DATE
    const hash = process.env.REACT_APP_HASH

    const brancDetails = branch === 'master'
        ? null
        : <><span>{branch}</span><span>{hash}</span><span>{date}</span></>

    return (
        <footer id='footer-column'>
            {brancDetails}
            <Link to='/disclaimer' className='disclaimer-link'>Gebruiksvoorwaarden</Link>
        </footer>
    )
}

export default memo(Footer)
