import React, { memo } from 'react'

const InfoTable = () => {
  return (
    <>
      <p>(Let op, sommige kolommen zijn uitgesplitst voor verschillende tabellen.)</p>
      <table className='table info-table'>
        <thead>
          <tr>
            <th className='section-table'>Tabel</th>
            <th>Kolom</th>
            <th>Naam</th>
            <th>Uitleg</th>
            <th>Formaat</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2.1A-D</td>
            <td>A</td>
            <td>Onderdeel</td>
            <td>Geeft aan om welke tabel het gaat</td>
            <td>Keuze uit:
              <ul>
                <li>2.1A</li>
                <li>2.1B</li>
                <li>2.1C</li>
                <li>2.1D</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1A-D</td>
            <td>B</td>
            <td>Eenheidscode</td>
            <td>Elke eenheid heeft een eenheidscode</td>
            <td>Alfanumeriek. Moet uniek zijn per onderdeel.</td>
          </tr>
          <tr>
            <td>2.1A</td>
            <td>C</td>
            <td>Postcode NL</td>
            <td>Elke eenheid heeft een postcode</td>
            <td>Postcode bestaat totaal uit 6 karakters (eerst 4 cijfers achtereenvolgend 2 letters, waarbij eerste cijfer alleen 1 t/m 9 kan zijn (geen 0)).</td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>C</td>
            <td>Postcode NL</td>
            <td />
            <td>Postcode kan leeg zijn; OF Postcode bestaat uit 4 cijfers waarbij eerste cijfer alleen 1 t/m 9 kan zijn (geen 0)); OF Postcode bestaat totaal uit 6 karakters (eerst 4 cijfers achtereenvolgend 2 letters, waarbij eerste cijfer alleen 1 t/m 9 kan zijn (geen 0)).</td>
          </tr>
          <tr>
            <td>2.1A</td>
            <td>D</td>
            <td>Huisnummer NL</td>
            <td />
            <td>Numeriek</td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>D</td>
            <td>Huisnummer NL</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>E</td>
            <td>Huisletter NL</td>
            <td />
            <td />
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>E</td>
            <td>Huisletter NL</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>F</td>
            <td>Huisnummertoevoeging</td>
            <td />
            <td />
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>F</td>
            <td>Huisnummertoevoeging</td>
            <td />
          </tr>
          <tr>
            <td>2.1A-D</td>
            <td>G</td>
            <td>Bag-verblijfsobject-id</td>
            <td></td>
            <td>Alfanumeriek. Maximaal 500 karakters.</td>
          </tr>
          <tr>
            <td>2.1A-B</td>
            <td>H</td>
            <td>Aantal primo verslagjaar</td>
            <td>Elke eenheid heeft een aantal primo verslagjaar, 0 indien nieuw gedurende verslagjaar.</td>
            <td />
          </tr>
          <tr>
            <td>2.1C-D</td>
            <td>H</td>
            <td>Aantal primo verslagjaar</td>
            <td>Moet niet worden ingevuld</td>
            <td />
          </tr>
          <tr>
            <td>2.1A-D</td>
            <td>I</td>
            <td>Aantal ultimo verslagjaar</td>
            <td>Elke eenheid heeft een aantal ultimo verslagjaar, 0 indien uit exploitatie gedurende verslagjaar.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>J</td>
            <td>Tak primo verslagjaar</td>
            <td>Elke eenheid heeft een tak primo verslagjaar. Indien nieuw gedurende verslagjaar dan gelijk aan ultimo verslagjaar (kolom I) opgeven.</td>
            <td>Keuze uit:
              <ul>
                <li>DAEB TI</li>
                <li>Niet-DAEB TI</li>
                <li>Geconsolideerde niet-DAEB verbindingen</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>J</td>
            <td>Tak primo verslagjaar</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A-D</td>
            <td>K</td>
            <td>Tak ultimo verslagjaar</td>
            <td>Elke eenheid heeft een tak primo verslagjaar. Indien weg gedurende verslagjaar dan gelijk aan ultimo vorig verslagjaar opgeven.</td>
            <td>Keuze uit:
              <ul>
                <li>DAEB TI</li>
                <li>Niet-DAEB TI</li>
                <li>Geconsolideerde niet-DAEB verbindingen</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1A</td>
            <td>L</td>
            <td>Eenheidsoort</td>
            <td>Elke eenheid heeft een eenheidsoort.</td>
            <td>Keuze uit:
              <ul>
                <li>Woonruimte</li>
                <li>Bedrijfsruimte</li>
                <li>Maatschappelijk vastgoed</li>
                <li>Intramuraal zorgvastgoed</li>
                <li>Parkeergelegenheid</li>
                <li>Overige</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>L</td>
            <td>Eenheidsoort</td>
            <td>Elke eenheid heeft een eenheidsoort.</td>
            <td>Keuze uit:
              <ul>
                <li>Woonruimte</li>
                <li>Bedrijfsruimte</li>
                <li>Maatschappelijk vastgoed</li>
                <li>Intramuraal zorgvastgoed</li>
                <li>Parkeergelegenheid</li>
                <li>Overige</li>
                <li>Grondposities</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1A</td>
            <td>M</td>
            <td>Soort woonruimte</td>
            <td>Indien Eenheidsoort (kolom K) is "woonruimte", dan is dit veld verplicht. Indien Eenheidsoort (kolom K) niet woonruimte is, dan is dit veld verplicht leeg.</td>
            <td>Keuze uit:
              <ul>
                <li>EGW</li>
                <li>MGW</li>
                <li>Flexwoning</li>
                <li>Studenteneenheid</li>
                <li>Extramuraal</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>M</td>
            <td>Soort woonruimte</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>N</td>
            <td>Is de woonruimte zelfstandig?</td>
            <td>Indien Eenheidsoort (Kolom K) is "woonruimte", dan is dit veld verplicht. Indien Eenheidsoort (kolom K) niet woonruimte is, dan is dit veld verplicht leeg.</td>
            <td>Keuze uit:
              <ul>
                <li>Ja</li>
                <li>Nee</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>N</td>
            <td>Is de woonruimte zelfstandig?</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>O</td>
            <td>Lift aanwezig?</td>
            <td>Indien Soort woonruimte (kolom L) is "MGW" dan is dit veld verplicht. Indien Soort woonruimte (kolom L) niet MGW is, dan dient dit veld leeg te zijn.</td>
            <td>Keuze uit:
              <ul>
                <li>Ja</li>
                <li>Nee</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>O</td>
            <td>Lift aanwezig?</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A-B</td>
            <td>P</td>
            <td>Mutatie verslagjaar</td>
            <td>Elke eenheid kent een type mutatie. Indien geen mutatie dan dient dit hier ook te worden opgegeven.</td>
            <td>Keuze uit:
              <ul>
                <li>Verkoop aan toekomstige bewoners</li>
                <li>Verkoop aan overigen</li>
                <li>Sloop</li>
                <li>Samenvoeging</li>
                <li>Nieuwbouw</li>
                <li>Aankoop</li>
                <li>Splitsing</li>
                <li>Van voorraad</li>
                <li>Naar voorraad</li>
                <li>Geen mutatie</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1C-D</td>
            <td>P</td>
            <td>Mutatie verslagjaar</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>Q</td>
            <td>Nettohuur per maand ultimo verslagjaar</td>
            <td>Elke eenheid kent een huurprijs per maand.</td>
            <td>Huurprijs moet twee decimalen bevatten.</td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>Q</td>
            <td>Nettohuur per maand ultimo verslagjaar</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>R</td>
            <td>Aantal WWS punten ultimo verslagjaar</td>
            <td>Indien Eenheidsoort (kolom K) is "woonruimte", dan is dit veld verplicht. Indien Eenheidsoort (kolom K) niet woonruimte is, dan is dit veld verplicht leeg.</td>
            <td>Aantal WWS-punten is maximaal 9999 en bevat géén decimalen.</td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>R</td>
            <td>Aantal WWS punten ultimo verslagjaar</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>S</td>
            <td>Energielabel</td>
            <td>Indien Is de woonruimte zelfstandig? (kolom M) is "Ja" dan is dit veld verplicht.</td>
            <td>Keuze uit:
              <ul>
                <li>A++++</li>
                <li>A+++</li>
                <li>A++</li>
                <li>A+</li>
                <li>A</li>
                <li>B</li>
                <li>C</li>
                <li>D</li>
                <li>E</li>
                <li>F</li>
                <li>G</li>
                <li>Onbekend</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>S</td>
            <td>Energielabel</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>T</td>
            <td>Bouwjaar</td>
            <td>Elke eenheid heeft een bouwjaar (jaartal).</td>
            <td>jjjj</td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>T</td>
            <td>Bouwjaar</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>U</td>
            <td>Renovatiejaar</td>
            <td>Jaartal</td>
            <td>jjjj</td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>U</td>
            <td>Renovatiejaar</td>
            <td>Moet niet worden ingevuld.</td>
            <td />
          </tr>
          <tr>
            <td>2.1A</td>
            <td>V</td>
            <td>m2</td>
            <td>Elke eenheid heeft een aantal vierkante meters.</td>
            <td>Vierkante meters worden in twee decimalen opgegeven.</td>
          </tr>
          <tr>
            <td>2.1B-D</td>
            <td>V</td>
            <td>m2</td>
            <td>Indien Eenheidsoort (kolom K) is "Grondposities", dan is dit veld verplicht. Indien Eenheidsoort (kolom K) niet "Grondposities" is, dan is dit veld verplicht leeg.</td>
            <td>Vierkante meters worden in twee decimalen opgegeven.</td>
          </tr>
          <tr>
            <td>2.1A-D</td>
            <td>W</td>
            <td>WOZ-waarde verslagjaar</td>
            <td>Elke eenheid heeft een WOZ-waarde.</td>
            <td>In hele euro's, zonder valutateken, altijd positief. Waarde is altijd zonder scheidingstekens.</td>
          </tr>
          <tr>
            <td>2.1A-D</td>
            <td>X</td>
            <td>Marktwaarde ultimo verslagjaar</td>
            <td>Elke eenheid heeft een marktwaarde.</td>
            <td>In hele euro's, zonder valutateken, mag ook negatief zijn. Waarde is altijd zonder scheidingstekens.</td>
          </tr>
          <tr>
            <td>2.1A-D</td>
            <td>Y</td>
            <td>Onderpand WSW inzet verslagjaar</td>
            <td>Voor elke eenheid dient te worden aangegeven of deze is ingezet als onderpand.</td>
            <td>Keuze uit:
              <ul>
                <li>Ja</li>
                <li>Nee</li>
                <li>Vrijgegeven</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1A-B</td>
            <td>Z</td>
            <td>Naam zekerheidsnemer</td>
            <td>Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Nee" dan is dit veld verplicht, tenzij Mutatie verslagjaar (kolom O) is "Verkoop aan toekomstige bewoners", "Verkoop aan overigen", "Sloop" of "Samenvoeging" dan verplicht leeg. Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Vrijgegeven" dan is dit veld verplicht leeg. Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Ja" dan is dit veld verplicht leeg of "WSW".</td>
            <td />
          </tr>
          <tr>
            <td>2.1C-D</td>
            <td>Z</td>
            <td>Naam zekerheidsnemer</td>
            <td>Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Nee" dan is dit veld verplicht. Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Vrijgegeven" dan is dit veld verplicht leeg. Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Ja" dan is dit veld verplicht leeg of "WSW".</td>
            <td />
          </tr>
          <tr>
            <td>2.1A-B</td>
            <td>AA</td>
            <td>Type zekerheid</td>
            <td>Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Nee" dan is dit veld verplicht, tenzij Mutatie verslagjaar (kolom O) is "Verkoop aan toekomstige bewoners", "Verkoop aan overigen", "Sloop" of "Samenvoeging" dan verplicht leeg. Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Vrijgegeven" dan is dit veld verplicht leeg. Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Ja" dan is dit veld verplicht leeg of "Hypotheek".</td>
            <td>Keuze uit:
              <ul>
                <li>Hypotheek</li>
                <li>Pandrecht huurpenningen</li>
                <li>Hypotheek en pandrecht huurpenningen</li>
                <li>Positieve verklaring hypotheek en/of positieve verklaring pandrecht huurpenningen</li>
                <li>Hypotheek en positieve verklaring pandrecht huurpenningen</li>
                <li>Pandrecht huurpenningen en positieve verklaring hypotheek</li>
                <li>Overig</li>
                <li>nvt (alleen voor 2.1B)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1C-D</td>
            <td>AA</td>
            <td>Type zekerheid</td>
            <td>Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Nee" dan is dit veld verplicht. Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Vrijgegeven" dan is dit veld verplicht leeg. Indien "Onderpand WSW inzet verslagjaar" (kolom X) is "Ja" dan is dit veld verplicht leeg of "Hypotheek".</td>
            <td>Keuze uit:
              <ul>
                <li>Hypotheek</li>
                <li>Pandrecht huurpenningen</li>
                <li>Hypotheek en pandrecht huurpenningen</li>
                <li>Positieve verklaring hypotheek en/of positieve verklaring pandrecht huurpenningen</li>
                <li>Hypotheek en positieve verklaring pandrecht huurpenningen</li>
                <li>Pandrecht huurpenningen en positieve verklaring hypotheek</li>
                <li>Overig</li>
                <li>nvt</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>2.1A-D</td>
            <td>AB</td>
            <td>Eigendomspercentage</td>
            <td>Elke eenheid kent een eigendomspercentage.</td>
            <td>Waarde kan niet negatief zijn. Moet groter dan 0% en maximaal 100%, dus 1, zijn. Maximaal vier decimalen dus 1,45% (0,0145) is correct, 3% (0,03) is correct, 4,678% (0,04678) is incorrect.</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default memo(InfoTable)
