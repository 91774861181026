import propTypes from 'prop-types'
import React, { memo } from 'react'



type PropType = {
    entrypoints?: ({ uri: string, label: string })[],
    handleProgress: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    show: boolean
}

/**
 * Entrypoint dropdown component for the user to choose an entrypoint
 */
const EntrypointInput: React.FC<PropType> = ({ entrypoints, handleProgress, show }) => {

      EntrypointInput.propTypes = {
        entrypoints: propTypes.arrayOf(propTypes.shape({
          uri: propTypes.string.isRequired,
          label: propTypes.string.isRequired
        }).isRequired).isRequired,
        handleProgress: propTypes.func.isRequired,
        show: propTypes.bool.isRequired
      }

    const options = entrypoints
        ? entrypoints
            .map((entrypoint, i) => <option id={String(i)} value={entrypoint.uri} key={entrypoint.uri}>{entrypoint.label}</option>)
        : null

    return show
        ? (
            <div className='form-container'>
                <select
                    id='entrypoint-select'
                    className='form-select'
                    onChange={handleProgress}
                >
                    <option value='' defaultValue={''} hidden>Kies het gewenste formulier</option>
                    {options}
                </select>
            </div>
        )
        : null
}

export default memo(EntrypointInput)
