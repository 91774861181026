import React, { memo } from 'react'

import RouteSelectionCard from './RouteSelectionCard'

/**
 * Route selection cards which serves as a container for the cards
 */
const RouteSelectionCards = () => {
    return (
        <div className="cards-holder">
            <div className="cards-holder-column">
                <RouteSelectionCard
                    id='convert-card'
                    header='WOZ Validatie'
                    subHeader='Upload het WOZ CSV-bestand en download het XBRL-bestand. Het CSV-bestand wordt eerst gevalideerd.'
                    route='/woz/convert'
                />
				<h5 className='info-link'>
					<a href='https://servicedesk.sbr-wonen.nl/support/solutions/articles/75000121155-handleiding-proces-dvi2023-woz-bezitstabel' target='_blank' rel='noopener noreferrer'>De proceshandleiding voor het indienen van het WOZ-bestand</a>
				</h5>
            </div>
            <div className="cards-holder-column">
                <RouteSelectionCard
                    id='convert-card'  // Should this be a unique id, or is it ok to use the same id as the WOZ card?
                    header="ABM Validatie"
                    subHeader='Upload het ABM CSV-bestand en download het XBRL-bestand. Let op! dit is een vrijwillig proces. De Aedes-benchmark kan ook na 30 juni worden ingediend!'
                    route="woz/convertABM"
                />
				<h5 className='info-link'>
					<a href='https://servicedesk.sbr-wonen.nl/support/solutions/articles/75000121157-handleiding-aedes-benchmark-2024' target='_blank' rel='noopener noreferrer'>De proceshandleiding voor het indienen van het ABM-bestand</a>
				</h5>
            </div>
        </div>
    )
}

export default memo(RouteSelectionCards)