import axios from 'axios'
import React, { memo, useState, useEffect } from 'react'

import Button from '../../shared/components/Button'
import CopyText from '../../shared/components/CopyText'
import EntrypointInput from './components/EntrypointInput'
import ExistingXbrlChoice from './components/ExistingXbrlChoice'
import FileUpload from '../../shared/components/FileUpload'
import KvkInput from './components/KvkInput'
import Messages, {MessageType} from '../../shared/components/Messages'
import Popover from '../../shared/components/Popover'
import Summaries from '../../shared/components/Summaries'
import WarningText from '../../shared/components/WarningText'

import { isFileNotTooBig, padNumber } from '../../shared/utils/utils'
import ErrorMessages from '../../shared/components/ErrorMessages'

/**
 * Main convert component representing the entire /convert page body
 */
const Convert = () => {

    const [csv, setCsv] = useState<File | null>(null)
    const [csvFileInputStatus, setCsvFileInputStatus] = useState('')
    const [hash, setHash] = useState('')
    const [entrypoint, setEntrypoint] = useState<string | null>(null)
    const [entrypoints, setEntrypoints] = useState([])
    const [messages, setMessages] = useState<MessageType[]>([])
    const [existingXbrl, setExistingXbrl] = useState(false)
    const [kvkNumber, setKvkNumber] = useState('')
    const [kvkNumberFormatStatus, setKvkNumberFormatStatus] = useState('')
    const [loading, setLoading] = useState(false)
    const [selectionMade, setSelectionMade] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [summaries, setSummaries] = useState([])
    const [xbrl, setXbrl] = useState<File | null>(null)
    const [xbrlFileInputStatus, setXbrlFileInputStatus] = useState('')
    const [xbrlBlob, setXbrlBlob] = useState<string | null>(null)

    /**
     * useEffect() is a new feature related to hooks in react. You can read about it here:
     * https://reactjs.org/docs/hooks-effect.html
     */
    useEffect(() => {
        const entrypointsUrl = process.env.NODE_ENV === 'production'
            ? '/api/entrypoints'
            : 'http://localhost:8080/api/entrypoints'
        axios
            .get(entrypointsUrl)
            .then(res => {
                setEntrypoints(res.data)
            })
            .catch(() => {
                const errMessage: MessageType = {
                    messageType: 'Server fout',
                    text: 'Er is een fout opgetreden bij het ophalen van de configuratie van de verschillende regimes.'
                };
                setMessages([errMessage])
            })
    }, [])

    /**
     * Triggered by the upload button which will clear previous
     * errors and then execute the upload
     */
    const clearErrorsAndExecuteConvert = () => {
        clearErrors()
        executeConvert()
    }

    /**
     * This is what triggers the download by creating the <a> attribute
     * and putting in on the dom. Once the user downloads the file, the
     * attribute is then removed from the dom
     */
    const download = (xbrlBlob: string) => {
        const element = document.createElement('a')
        const filename = kvkNumber

        const d: Date = new Date()
        const xbrlDate = String(d.getFullYear())
            + padNumber(d.getMonth() + 1)
            + padNumber(d.getDate())
            + d.getHours()
            + padNumber(d.getMinutes())
            + padNumber(d.getSeconds())
        const documentName = filename + '-' + xbrlDate + '.xbrl'

        const nav = navigator as any        
        if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(new Blob([atob(xbrlBlob)], { type: 'data:application/xbrl;charset=utf-8;base64' }), documentName)
        } else {
            element.setAttribute('href', 'data:application/xbrl;charset=utf-8;base64,' + encodeURIComponent(xbrlBlob))
            element.setAttribute('download', documentName)

            element.style.display = 'none'
            document.body.appendChild(element)

            element.click()

            document.body.removeChild(element)
        }
    }

    const executeConvert = () => {
        setLoading(true)
        const convertUrl = process.env.NODE_ENV === 'production'
            ? '/api/woz/convert'
            : 'http://localhost:8080/api/woz/convert'
        const data = new window.FormData()
        data.append('csv', csv!, csv!.name)
        if (xbrl) {
            data.append('xbrl', xbrl, xbrl.name)
        } else {
            data.append('entityIdentifier', kvkNumber)
            data.append('entrypoint', entrypoint!) // todo use union type
        }
        axios
            .post(
                convertUrl,
                data,
                {
                    headers:
                        { 'Content-Type': 'application/x-www-form-urlencoded' }
                }
            )
            .then(res => {
                if (res.data.xbrlInstance) {
                    const resMessages = (res.data.messages && res.data.messages.length !== 0)
                        ? res.data.messages
                        : []
                    setXbrlBlob(res.data.xbrlInstance)
                    setKvkNumber(res.data.kvkNumber)
                    setHash(res.data.summary.WozSummary.hash)
                    setSummaries(res.data.summary.WozSummary.totals)
                    setMessages(resMessages)
                } else {
                    setXbrlBlob(null)
                    setMessages(res.data.messages)
                }
                setLoading(false)
            })
            .catch(() => {
                const errMessage = {
                    messageType: 'Server fout',
                    text: 'Er is een fout opgetreden bij het verwerken van het CSV-bestand.'
                }
                setLoading(false)
                setMessages([errMessage])
            })
    }

    const success: boolean = xbrlBlob !== null && xbrlBlob !== undefined

    const clearErrors = () => {
        setMessages([])
    }

    /**
     * Based on the specific type of event, this is what determines
     * what is conditionally being shown on the convert form
     */
    const handleProgress = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        if (e.target instanceof HTMLInputElement) {
            let file: File
            switch (e.target.id) {
                case 'csv-upload':
                    file = e.target.files?.item(0)!
                    if (isFileNotTooBig(file.size)) {
                        setCsv(file)
                        setCsvFileInputStatus('is-success')
                    } else {
                        setCsv(null)
                        setCsvFileInputStatus('is-error')
                    }
                    break
                case 'xbrl-upload':
                    file = e.target.files?.item(0)!
                    if (isFileNotTooBig(file.size)) {
                        setXbrl(file)
                        setXbrlFileInputStatus('is-success')
                        setShowWarning(true)
                    } else {
                        setXbrlFileInputStatus('is-error')
                    }
                    break
                case 'existing-xbrl':
                    clearErrors()
                    setExistingXbrl(true)
                    setSelectionMade(true)
                    setShowWarning(true)
                    setXbrlFileInputStatus('')
                    break
                case 'no-xbrl':
                    clearErrors()
                    setExistingXbrl(false)
                    setSelectionMade(true)
                    setXbrl(null)
                    setShowWarning(true)
                    setXbrlFileInputStatus('')
                    break
                default:
            }
        } else if (e.target instanceof HTMLSelectElement) {
            setEntrypoint(e.target.value)
        }
    }

    const showSuccess = (
        <div>
            <FileUpload
                handleProgress={e => handleProgress(e)}
                fileExtension='.csv'
                fileType='csv'
                show
                successFailure={csvFileInputStatus}
            />
            <ExistingXbrlChoice
                handleProgress={handleProgress}
                show={csvFileInputStatus === 'is-success'}
            />
            <FileUpload
                fileType='xbrl'
                fileExtension='.xbrl'
                handleProgress={handleProgress}
                show={existingXbrl}
                successFailure={xbrlFileInputStatus}
            />
            <div className="form-container form-group">
                <KvkInput
                    show={selectionMade && !existingXbrl}
                    kvkNumber={kvkNumber}
                    setKvkNumber={s => setKvkNumber(s)}
                    status={kvkNumberFormatStatus}
                    setStatus={s => setKvkNumberFormatStatus(s)}
                    labelCls={""}
                    inputCls={""}
                    helpCls={""}
                />
            </div>
            <EntrypointInput
                handleProgress={handleProgress}
                entrypoints={entrypoints}
                show={selectionMade && !existingXbrl}
            />
            <WarningText
                warningLocation='conversion'
                show={showWarning}
            />
            <Button
                action={clearErrorsAndExecuteConvert}
                buttonText='Upload'
                clickable={!!(csv && xbrlFileInputStatus === 'is-success') ||
                !!(csv && entrypoint && kvkNumberFormatStatus === 'is-success')}
                container
                id='submit'
                loading={loading}
                show
            />
            <Popover
                show
                text='Waaraan voldoet een correct WOZ CSV-bestand?'
                infoChoice='info'
            />
        </div>
    )

    return (
        <div className='main-content-container container grid-lg'>
            <CopyText
                heading='Controlegetal van het CSV-bestand'
                scroll={false}
                show={success}
                text={hash}
            />
            <ErrorMessages
                show={!!messages.filter(message => message.messageType !== 'Informatief').length}
            />
            <Messages
                hasMessages={!!messages}
                messages={messages}
            />
            <WarningText
                warningLocation='results'
                show={success}
            />
            <Button
                action={() => download(xbrlBlob!)}
                buttonText='Download XBRL'
                clickable={success}
                container={false}
                id='download'
                show={success}
                loading={false}
            />
            <Summaries summaries={summaries} />
            {success
                ? null
                : showSuccess}
        </div>
    )
}

export default memo(Convert)
