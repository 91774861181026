import React, { memo } from 'react'

const Disclaimer = () => {
    return (
        <div className='main-content-container container grid-lg'>
            <h5>Gebruikersvoorwaarden WOZ-validatieservice</h5>

            <p> Door gebruik te maken van de dienst WOZ-validatieservice gaat u akkoord met onderstaande gebruikersvoorwaarden:</p>

            <ul id='disclaimer-ul'>
                <li>U blijft altijd verantwoordelijk voor de geüploade gegevens;</li>
                <li>Wij beveiligen de door u geüploade gegevens zorgvuldig en gebruiken deze uitsluitend voor onze dienstverlening;</li>
                <li>Indien de geüploade content persoonsgegevens bevat verwerken wij deze conform het privacybeleid van SBR-wonen.
                    U leest hier meer over in onze <a href='https://sbr-wonen.nl/privacyverklaring/' id='sbr-text'>Privacyverklaring</a>;
                </li>
                <li>Wij bewaren geen kopieën en/of back-ups en niemand heeft toegang tot uw gegevens;</li>
                <li>Uw gegevens worden op geen enkele wijze gedeeld met derde partijen voor andere doeleinden dan de dienstverlening van de WOZ-validatieservice.</li>
            </ul>

            <p>Heeft u vragen? Stuur deze gerust naar <a href='mailto:info@sbr-wonen.nl' id='sbr-text'>info@sbr-wonen.nl</a></p>

        </div>
    )
}

export default memo(Disclaimer)
