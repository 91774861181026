import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { memo } from 'react'

type PropType = {
    id: string,
    header: string,
    subHeader: string,
    route: string
}
/**
 * Route selection card representing each route that is available to the user
 */
const RouteSelectionCard: React.FC<PropType> = ({ id, header, subHeader, route }) => {
    RouteSelectionCard.propTypes = {
        id: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        subHeader: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired
    }
    return (
        <div className='card' id={id}>
            <div className='card-header'>
                <div className='card-title h2'>{header}</div>
                <div className='card-subtitle text-gray'>{subHeader}</div>
            </div>
            <div className='card-body'>
                <Link
                    to={route}
                    id={id + '-link'}
                >
                    <button className='btn btn-primary'>Verder</button>
                </Link>
            </div>
        </div>
    )
}

export default memo(RouteSelectionCard)
