/**
 * Capitalizes the first letter of a string if the string is longer than 0
 *  If it is an empty string, it simply returns the empty string
 * @param {String} string
 * @returns {String}
 */
const capitalize = (string: string) => {
    return string && string.length > 0
        ? string[0].toUpperCase() + string.slice(1)
        : string
}

/**
 * Group an array of objects by specific key
 * @param {Array} arr
 * @param {String} key
 * @returns {Object}
 */
const groupBy = (arr: any[], key: string | number) => {
    return arr.reduce((acc, cv) => {
        (acc[cv[key]] = acc[cv[key]] || []).push(cv)
        return acc
    }, {})
}

/**
 * Used to check the filesize of various uploads
 * @param {Number} fileSize
 * @returns {Boolean}
 */
const isFileNotTooBig = (fileSize: number) => fileSize < 20971520 // 20mb

/**
 * More detailed check to verify if a value is numeric
 * @param {Number} n
 * @returns {Boolean}
 */
const isNumeric = (n: string) => {
    const num = parseInt(n)
    return !isNaN(num) && isFinite(num)
}

/**
 * Takes in a month or date and pads it with a 0 if it's less than 10
 * Needs to be forced into a string to keep the 0
 * All the non-JS type-strict people go crazy ¯\_(ツ)_/¯
 * @param {Number} number
 * @returns {String}
 */
const padNumber = (number: number | string) => number < 10 ? '0' + number : number

/**
 * Sort an array of objects by specific key
 * @param {Array} arr
 * @param {String} key
 * @returns {Array}
 */
const sortByKey = (arr: any[], key: number) => {
    return arr.sort((a, b) => {
        const x = a[key]
        const y = b[key]
        return ((x < y) ? -1 : ((x > y) ? 1 : 0))
    })
}

export {
    capitalize,
    groupBy,
    isNumeric,
    isFileNotTooBig,
    padNumber,
    sortByKey
}
