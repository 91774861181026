import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import 'spectre.css/dist/spectre.min.css'
import 'spectre.css/dist/spectre-icons.min.css'
import './style/index.css'

import Convert from './js/views/Convert/Convert'
import ConvertABM from './js/views/Convert/ConvertABM'
import Header from './js/shared/components/Header'
import Info from './js/views/Info/Info'
import InfoAbm from './js/views/Info/InfoAbm'
import Footer from './js/shared/components/Footer'
import Main from './js/views/Main/Main'
import Disclaimer from './js/views/Disclaimer/Disclaimer'

ReactDOM.render(
  <Router>
    <div id='wrapper'>
      <Header />
      <Route path='/' exact component={Main} />
      <Route path='/woz/convert' component={Convert} />
      <Route path='/woz/convertABM' component={ConvertABM} />
      <Route path='/info' component={Info} />
      <Route path='/infoAbm' component={InfoAbm} />
      <Route path='/disclaimer' component={Disclaimer} />
      <Footer />
    </div>
  </Router>,
  document.getElementById('root')
)
