import { Link } from 'react-router-dom'
import React, { memo } from 'react'

const Header = () => {
    return (
        <header>
            <div className='header-column'>
                <Link to='/'>
                    <div className='logo' />
                </Link>
            </div>
        </header>
    )
}

export default memo(Header)
