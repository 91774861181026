import propTypes from 'prop-types'
import React, { memo } from 'react'

import { groupBy } from '../utils/utils'

type MessageType = {
    columns?: number[] | null,
    line?: number[] | null,
    messageType: string,
    text: string
}

type MessagesPropType = {
    hasMessages: boolean,
    messages: MessageType[]
}

/**
 * Messages component which also includes <MessageGroup /> and <Message /> components
 */
const Messages: React.FC<MessagesPropType> = ({ hasMessages, messages }) => {

      Messages.propTypes = { // todo auto-generate
        hasMessages: propTypes.bool.isRequired,
        messages: propTypes.arrayOf(
          propTypes.shape({
            /**
             * Columns which currently are not filled in by the backend, therefore are null
             */
            columns: propTypes.arrayOf(propTypes.number.isRequired),
            /**
             * Line number that the message is referencing
             */
            line: propTypes.arrayOf(propTypes.number.isRequired),
            messageType: propTypes.string.isRequired,
            text: propTypes.string.isRequired
          }).isRequired
        ).isRequired
      }

    if (hasMessages) {
        const groupMessages = groupBy(messages, 'messageType')
        return(<>{
            Object.keys(groupMessages)
                .map(type => {
                    return <MessageGroup key={type} type={type} messages={groupMessages[type]} />
                })
        }</>)
    } else {
        return null
    }
}

type MessageGroupPropType = {
    messages?: MessageType[],
    type?: string
}

/**
 * Messages group components which displays a group of messages of one type
 */
const MessageGroup: React.FC<MessageGroupPropType> = ({ messages, type }) => {

      MessageGroup.propTypes = {
        messages: propTypes.arrayOf(
          propTypes.shape({
              messageType: propTypes.string.isRequired,
              text: propTypes.string.isRequired
          }).isRequired
        ),
        type: propTypes.string.isRequired
      }

    if (messages) {
        const messageComponents = messages.map((message, i) => <Message key={message.text + i} lines={message.line ?? undefined} text={message.text} />)
        return (
            <div id='messages'>
                <h5>{type}</h5>
                {messageComponents}
            </div>
        )
    } else return null
}

type MessagePropType = {
    text: string,
    lines?: number[]
}

/**
 * Message component
 */
const Message: React.FC<MessagePropType> = ({ text, lines }) => {

    Message.propTypes = {
      text: propTypes.string.isRequired,
      lines: propTypes.arrayOf(propTypes.number.isRequired)
    }

    const lineInfo = lines
        ? <span>{lines.length > 1 ? 'Regelnummers' : 'Regelnummer'} : {lines.join(', ')}</span>
        : null

    return (
        <div className='toast toast-error'>
            {text}
            <br />
            {lineInfo}
        </div>
    )
}

export default memo(Messages)
export type { MessageType }