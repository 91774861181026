import propTypes from 'prop-types'
import React, { memo } from 'react'

type PropType = {
    action: () => void,
    buttonText: string,
    clickable: boolean,
    container: any,
    id: string,
    loading: boolean,
    show: boolean,
    extraClasses?: string
}
/**
 * Alternative button that allows for extra props
 */
const Button: React.FC<PropType> = ({ action, buttonText, clickable, container, id, loading, show, extraClasses }) => {
    Button.propTypes = {
        action: propTypes.func.isRequired,
        buttonText: propTypes.string.isRequired,
        clickable: propTypes.bool.isRequired,
        /**
         * Boolean value of whether there should be a padding container around button
         */
        container: propTypes.bool.isRequired,
        /**
         * String value for a custom id
         */
        id: propTypes.string.isRequired,
        /**
         * Boolean value of whether button should show the loading icon
         */
        loading: propTypes.bool.isRequired,
        show: propTypes.bool.isRequired,
        extraClasses: propTypes.string
    }

    const buttonShow = (
        <div className={container ? 'form-container' : ''}>
            <button
                className={'btn btn-primary form-button' +
                (!loading ? '' : ' loading') +
                (!clickable ? ' disabled' : ' enabled') +
                (extraClasses ? (' ' + extraClasses) : '')}
                id={id || ''}
                onClick={action}
            >
                {buttonText}
            </button>
        </div>
    )
    return show
        ? buttonShow
        : null
}

export default memo(Button)
