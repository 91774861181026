import propTypes from 'prop-types'
import React, { memo } from 'react'

type PropType = {
    handleProgress: (e: React.ChangeEvent<HTMLInputElement>) => void,
    show: boolean
}

/**
 * Section of form where the user chooses whether they have an existing
 * XBRL or they'd like to provide an entrypoint and KVK number
 */
const ExistingXbrlChoice: React.FC<PropType> = ({ handleProgress, show }) => {
    ExistingXbrlChoice.propTypes = {
        handleProgress: propTypes.func.isRequired,
        show: propTypes.bool.isRequired
    }
    return show
        ? (
            <div className='form-container'>
                <label className='form-label'>Heeft u reeds een bestaand XBRL-bestand om samen te voegen met de gegevens uit het CSV-bestand?</label>
                <label
                    className='form-radio'
                    htmlFor='existing-xbrl'
                >
                    <input
                        id='existing-xbrl'
                        type='radio'
                        name='existing-xbrl'
                        onChange={handleProgress}
                    />
                    <i className='form-icon' />
                    Ja, ik heb al een XBRL-bestand dat ik wil uploaden.
                </label>
                <label
                    className='form-radio'
                    htmlFor='no-xbrl'
                >
                    <input
                        id='no-xbrl'
                        type='radio'
                        name='existing-xbrl'
                        onChange={handleProgress}
                    />
                    <i className='form-icon' />
                    Nee, ik wil graag een XBRL-bestand laten genereren met gegevens uit het CSV-bestand.
                </label>
            </div>
        )
        : null
}

export default memo(ExistingXbrlChoice)
