import propTypes from 'prop-types'
import React, { memo } from 'react'

import SummarySection from './SummarySection'
import {SummaryTablePropType, summaryTablePropType} from "./SummaryTable";

type PropType = {
    summaries: { section: string, tables: SummaryTablePropType[] }[]
}
/**
 * Summary component that contains summary sections
 */
const Summaries: React.FC<PropType> = ({ summaries }) => {

    Summaries.propTypes = {
        summaries: propTypes.arrayOf(
            propTypes.shape({
                section: propTypes.string.isRequired,
                tables: propTypes.arrayOf(propTypes.shape(summaryTablePropType).isRequired).isRequired
            }).isRequired).isRequired
    }

    return summaries
        ?
          <>{
              summaries.map(summary => {
                  return (
                      <div key={summary.section}>
                          <h3>{summary.section}</h3>
                          <SummarySection tables={summary.tables} />
                      </div>
                  )
              })
          }</>
        : null
}

export default memo(Summaries)
