import propTypes from 'prop-types'
import React, { memo } from 'react'

import SummaryTable, {summaryTablePropType, SummaryTablePropType} from './SummaryTable'

/**
 * Summary section component that contains summary tables
 */
const SummarySection: React.FC<{ tables: SummaryTablePropType[] }> = ({ tables } ) => {

    SummarySection.propTypes = {
        tables: propTypes.arrayOf(propTypes.shape(summaryTablePropType).isRequired).isRequired
    }

    return tables
        ?
            <>{
                tables.map((table, i) => {
                    return (
                        <div key={"table" + i}>
                            <SummaryTable
                                financialStatementsType={table.financialStatementsType}
                                headerRow={table.headerRow}
                                rows={table.rows}
                            />
                        </div>
                    )
                })
            }</>
        : null
}

export default memo(SummarySection)