import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'

type PropType = {
    heading: string,
    scroll: boolean,
    show: boolean,
    text: string
}
/**
 * Text component that allows for text to be clicked and copied
 */
const CopyText: React.FC<PropType> = ({ heading, show, scroll, text }) => {
    CopyText.propTypes = {
        heading: PropTypes.string.isRequired,
        scroll: PropTypes.bool.isRequired,
        show: PropTypes.bool.isRequired,
        text: PropTypes.string.isRequired
    }

    const [copySuccess, setCopySuccess] = useState(false)

    const copyToClipBoard = () => {
        const copyTarget = document.createElement('textarea')
        copyTarget.value = text
        document.body.appendChild(copyTarget)
        copyTarget.select()
        document.execCommand('copy')
        document.body.removeChild(copyTarget)
        setCopySuccess(true)
        setInterval(() => { setCopySuccess(false) }, 2000)
    }

    const initialSpanClass = 'clickable-text-area label labe-default'
    const spanClass = scroll ? initialSpanClass + ' scroller' : initialSpanClass

    return show
        ? (
            <>
                <h5>{heading}</h5>
                {copySuccess
                    ? <h5 className='copied'>gekopieerd!</h5>
                    : ''}
                <span
                    className={spanClass}
                    onClick={copyToClipBoard}
                >
          <i className='icon icon-copy' />
                    {text}
        </span>
            </>
        )
        : null
}

export default memo(CopyText)
