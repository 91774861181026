import React, { memo } from 'react'

import RouteSelectionCards from './components/RouteSelectionCards'

const Main = () => {
    return (
        <div className='main-content-container container grid-lg'>
            <RouteSelectionCards />
        </div>
    )
}

export default memo(Main)
