import React, { memo } from 'react'

const InfoTableAbm = () => {

  return (
    <>
      <table className='table info-table'>
        <thead>
          <tr>
            <th className='section-table'>Kolom</th>
            <th>Naam</th>
            <th>Uitleg</th>
            <th>Formaat</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A</td>
            <td>Eenheidscode</td>
            <td>Elke eenheid heeft een eenheidscode</td>
            <td>Tekst. Moet uniek zijn.</td>
          </tr>
          <tr>
              <td>B</td>
              <td>Complex_id</td>
              <td></td>
              <td>Tekst</td>
          </tr>
          <tr>
              <td>C</td>
              <td>Postcode NL</td>
              <td></td>
              <td>Postcode kan leeg zijn; OF Postcode bestaat uit 4 cijfers waarbij eerste cijfer alleen 1 t/m 9 kan zijn (geen 0)); OF Postcode bestaat totaal uit 6 karakters (eerst 4 cijfers achtereenvolgend 2 letters, waarbij eerste cijfer alleen 1 t/m 9 kan zijn (geen 0)).</td>
          </tr>
          <tr>
              <td>D</td>
              <td>Huisnummer NL</td>
              <td></td>
              <td>Numeriek</td>
          </tr>
          <tr>
              <td>E</td>
              <td>Huisnummertoevoeging</td>
              <td></td>
              <td></td>
          </tr>
          <tr>
              <td>F</td>
              <td>Aantal verhuureenheden</td>
              <td></td>
              <td>Numeriek</td>
          </tr>
          <tr>
              <td>G</td>
              <td>Woningtype</td>
              <td></td>
              <td>Keuze uit:
                  <ul>
                      <li>EengezWon</li>
                      <li>MeergezWon_ZndLift</li>
                      <li>MeergezWon_Lift</li>
                      <li>Onzelfstandig_Eenh</li>
                      <li>Intramuraal</li>
                      <li>Overige</li>
                      <li>Aansluitregel</li>
                      <li>Flexwoning</li>
                  </ul>
              </td>
          </tr>
          <tr>
              <td>H</td>
              <td>Bouwjaar</td>
              <td></td>
              <td>Jaar van datum - jjjj</td>
          </tr>
          <tr>
              <td>I</td>
              <td>Reparatieonderhoud</td>
              <td>Verplicht veld</td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>J</td>
              <td>Mutatieonderhoud</td>
              <td>Verplicht veld</td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>K</td>
              <td>Planmatig onderhoud</td>
              <td>Verplicht veld</td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>L</td>
              <td>Investeringen woningverbetering</td>
              <td>Verplicht veld</td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>M</td>
              <td>Bouwkosten (nieuwbouw)</td>
              <td></td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>N</td>
              <td>Grondkosten (nieuwbouw)</td>
              <td></td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
          <tr>
              <td>O</td>
              <td>Overige kosten (nieuwbouw)</td>
              <td></td>
              <td>In euro's, zonder valutateken, met maximaal 2 decimalen en maximaal 20 cijfers.</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default memo(InfoTableAbm)
