import propTypes from 'prop-types'
import React, { memo } from 'react'

type Row = {
    cells: string[],
    classOfTotalUnitsInExploitation: string
}

type SummaryTablePropType = {
    financialStatementsType: string,
    headerRow: string[],
    rows: Row[]
}

const summaryTablePropType = {
    financialStatementsType: propTypes.string.isRequired,
    headerRow: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
    rows: propTypes.arrayOf(
        propTypes.shape({
            cells: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
            classOfTotalUnitsInExploitation: propTypes.string.isRequired
        }).isRequired
    ).isRequired
}
/**
 * Summary table component
 */
const SummaryTable: React.FC<SummaryTablePropType> = ({ financialStatementsType, headerRow, rows }) => {
    SummaryTable.propTypes = summaryTablePropType

    /**
     * Maps over the header props and creates an array of Table heading components
     */
    const tableHeadings = headerRow
        .map(header => <TableHeading key={header} name={header} />)

    const addingSeparator = (cells: string[]): string[] => {
        return cells.map((cell) => cell.replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
    }

    /**
     * Creates an array of table row components
     */
    const tableRows = rows
        .map((row, i) =>
            <TableRow key={financialStatementsType + i} name={row.classOfTotalUnitsInExploitation} cells={addingSeparator(row.cells)} />
        )

    return (
        <div className='table-container'>
            <h4 className='summary-table-heading'>{financialStatementsType}</h4>
            <table className='table table-striped'>
                <thead>
                <tr>
                    <th />
                    {tableHeadings}
                </tr>
                </thead>
                <tbody>
                {tableRows}
                </tbody>
            </table>
        </div>
    )
}


const TableHeading: React.FC<{name: string}> = ({ name }) => {
    TableHeading.propTypes = {
        name: propTypes.string.isRequired
    }
    return <th>{name}</th>
}

const TableRow: React.FC<{ cells: string[], name: string }> = ({ cells, name }) => {
    TableRow.propTypes = {
        cells: propTypes.arrayOf(propTypes.string.isRequired).isRequired,
        name: propTypes.string.isRequired
    }
    const renderedCells = cells.map(cell => <td className='table-data' key={cell}>{cell}</td>)
    return (<tr><td><b>{name}</b></td>{renderedCells}</tr>)
}

export default memo(SummaryTable)
export type { SummaryTablePropType }
export { summaryTablePropType }
