import axios from 'axios'
import React, { memo, useState } from 'react'

import Button from '../../shared/components/Button'
import FileUpload from '../../shared/components/FileUpload'
import Messages, {MessageType} from '../../shared/components/Messages'
import Popover from '../../shared/components/Popover'

import { isFileNotTooBig, padNumber } from '../../shared/utils/utils'
import ErrorMessages from '../../shared/components/ErrorMessages'
import KvkInput from "./components/KvkInput";
import ExistingXbrlChoice from "./components/ExistingXbrlChoice";
import CopyText from "../../shared/components/CopyText";
import Summaries from "../../shared/components/Summaries";

/**
 * Main convert component representing the entire /convert page body
 */
const ConvertABM = () => {

    const [messages, setMessages] = useState<MessageType[]>([])
    const [loading, setLoading] = useState(false)

    const [csv, setCsv] = useState<File | null>(null)
    const [csvFileInputStatus, setCsvFileInputStatus] = useState('')
    const [hash, setHash] = useState('')

    const [selectionMade, setSelectionMade] = useState(false)
    const [existingXbrl, setExistingXbrl] = useState(false)
    const [kvkNumber, setKvkNumber] = useState('')
    const [kvkNumberFormatStatus, setKvkNumberFormatStatus] = useState('')
    const [xbrl, setXbrl] = useState<File | null>(null)
    const [xbrlFileInputStatus, setXbrlFileInputStatus] = useState('')

    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [xbrlBlob, setXbrlBlob] = useState<string | null>(null)
    const [summaries, setSummaries] = useState<any>(null)

    // todo put in util/component?
    /**
     * This is what triggers the download by creating the <a> attribute
     * and putting in on the dom. Once the user downloads the file, the
     * attribute is then removed from the dom
     */
    const download = () => {
        const element = document.createElement('a')
        const filename = 'Aedes-benchmark'

        const d = new Date()
        const xbrlDate = String(d.getFullYear())
            + padNumber(d.getMonth() + 1)
            + padNumber(d.getDate())
            + d.getHours()
            + padNumber(d.getMinutes())
            + padNumber(d.getSeconds())
        const documentName = filename + '-' + xbrlDate + '.xbrl'

        // This saves the generated xbrl instance to disk
        const nav = navigator as any        
        if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(new Blob([atob(xbrlBlob!)], { type: 'data:application/xbrl;charset=utf-8;base64' }), documentName)
        } else {
            element.setAttribute('href', 'data:application/xbrl;charset=utf-8;base64,' + encodeURIComponent(xbrlBlob!))
            element.setAttribute('download', documentName)

            element.style.display = 'none'
            document.body.appendChild(element)

            element.click()

            document.body.removeChild(element)
        }
    }

    /**
     * Triggered by the upload button which will clear previous
     * errors and then execute the upload
     */
    const doUpload = () => {
        setMessages([])
        handleSubmit()
    }

    const handleSubmit = () => {
        setLoading(true)
        const convertUrl = process.env.NODE_ENV === 'production'
            ? '/api/abm'
            : 'http://localhost:8080/api/abm'
        const data = new window.FormData()
        data.append('csv', csv!, csv!.name)

        if (xbrl) {
            data.append('xbrl', xbrl, xbrl.name)
        } else {
            data.append('entityIdentifier', kvkNumber)
        }

        axios
            .post(
                convertUrl,
                data,
                {
                    headers:
                        { 'Content-Type': 'application/x-www-form-urlencoded' }
                }
            )
            .then(res => {
                if (res.data.xbrlInstance) {
                    const resMessages = (res.data.messages && res.data.messages.length !== 0)
                        ? res.data.messages
                        : []
                    setMessages(resMessages)
                    setXbrlBlob(res.data.xbrlInstance)
                    setKvkNumber(res.data.kvkNumber)
                    setHash(res.data.summary.AbmSummary.hash)
                    setSummaries(res.data.summary.AbmSummary)
                    setSubmitSuccess(true)
                } else {
                    setXbrlBlob(null)
                    setMessages(res.data.messages)
                    setSubmitSuccess(false)
                }
                setLoading(false)
            })
            .catch(() => {
                const errMessage: MessageType = {
                    messageType: 'Server fout',
                    text: 'Er is een fout opgetreden bij het verwerken van het CSV-bestand.'
                }
                setLoading(false)
                setMessages([errMessage])
            })
    }

    /**
     * Based on the specific type of event, this is what determines
     * what is conditionally being shown on the convert form
     */
    const handleProgress = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let file: File
        switch (e.target.id) {
            case 'csv-upload':
                file = e.target.files?.item(0)!
                if (isFileNotTooBig(file.size)) { // Is file ok re size?
                    setCsv(file)
                    setCsvFileInputStatus('is-success')
                } else {
                    setCsv(null)
                    setCsvFileInputStatus('is-error')
                }
                break
            case 'no-xbrl':
                setExistingXbrl(false)
                setSelectionMade(true)
                setXbrl(null)
                setXbrlFileInputStatus('')
                break
            case 'existing-xbrl':
                setExistingXbrl(true)
                setSelectionMade(true)
                setXbrlFileInputStatus('')
                break
            case 'xbrl-upload':
                file = e.target.files?.item(0)!
                if (isFileNotTooBig(file.size)) {
                    setXbrl(file)
                    setXbrlFileInputStatus('is-success')
                } else {
                    setXbrlFileInputStatus('is-error')
                }
                break
            default:
        }
    }

    const uploadPart = (
        <div>
            <FileUpload
                fileType='csv'
                fileExtension='.csv'
                handleProgress={handleProgress}
                show
                successFailure={csvFileInputStatus} // css class name for status
            />
            <ExistingXbrlChoice
                handleProgress={handleProgress}
                show={csvFileInputStatus === 'is-success'}
            />
            <FileUpload
                fileType='xbrl'
                fileExtension='.xbrl'
                handleProgress={handleProgress}
                show={selectionMade && existingXbrl}
                successFailure={xbrlFileInputStatus}
            />
            <div className="form-container form-group">
                <KvkInput
                    show={selectionMade && !existingXbrl}
                    kvkNumber={kvkNumber}
                    setKvkNumber={s => setKvkNumber(s)}
                    status={kvkNumberFormatStatus}
                    setStatus={s => setKvkNumberFormatStatus(s)}
                    labelCls={""}
                    inputCls={""}
                    helpCls={""}
                />
            </div>
            <Button
                action={doUpload}
                buttonText="Upload"
                clickable={
                    csvFileInputStatus === 'is-success' &&
                    (kvkNumberFormatStatus === 'is-success' || xbrlFileInputStatus === 'is-success')
                }
                container
                id='submit'
                loading={loading}
                show
            />
            <Popover
                show
                text='Waaraan voldoet een correct ABM CSV-bestand?'
                infoChoice='infoAbm'
            />
        </div>)

    return (
        <div className='main-content-container container grid-xl'>
            {submitSuccess || uploadPart}
            <CopyText
                heading='Controlegetal van het CSV-bestand'
                scroll={false}
                show={submitSuccess}
                text={hash}
            />
            <ErrorMessages
                show={!!messages.filter(message => message.messageType !== 'Informatief').length}
            />
            <Messages
                hasMessages={!!messages}
                messages={messages}
            />
            <Button
                action={download}
                buttonText='Download XBRL'
                container
                id='download'
                show={submitSuccess}
                clickable
                loading={false}
            />
            {submitSuccess && <Summaries summaries={
                [{
                    section: "CSV-totalen",
                    tables: [{
                        financialStatementsType: "Onderstaande waardes betreffen totalen uit het Aedes-benchmarktabel. Deze worden overgenomen in het Aedes-benchmark portaal bij het inladen van het XBRL-bestand.",
                        headerRow: ["Totaal"],
                        rows: [{
                            cells: [summaries!.repairMaintenance],
                            classOfTotalUnitsInExploitation: "Reparatieonderhoud"
                        }, {
                            cells: [summaries!.mutationMaintenance],
                            classOfTotalUnitsInExploitation: "Mutatieonderhoud"
                        }, {
                            cells: [summaries!.plannedMaintenance],
                            classOfTotalUnitsInExploitation: "Planmatig onderhoud"
                        }, {
                            cells: [summaries!.investmentsInHomeImprovements],
                            classOfTotalUnitsInExploitation: "Investeringen woningverbetering"
                        }]
                    }]
                }]
            }/>}
        </div>
    )
}

export default memo(ConvertABM)
