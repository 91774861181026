import { Link } from 'react-router-dom'
import propTypes from 'prop-types'
import React, { memo } from 'react'

type PropType = {
    show: boolean,
    text: string,
    infoChoice: string
}
/**
 * Popover that appears when you hover over help text.
 * Links to the rules that determine whether the CSV file is correctly formed.
 */
const Popover: React.FC<PropType> = ({ show, text, infoChoice }) => {
    Popover.propTypes = {
        show: propTypes.bool.isRequired,
        text: propTypes.string.isRequired,
        infoChoice: propTypes.string.isRequired
    }

    const infoPath = '/' + infoChoice

    return show
        ? (
            <div className='info-link-container'>
                <Link to={infoPath} className='popover popover-top'>
                    <h5 className='info-link'>
                        {text}
                    </h5>
                    <div className='popover-container'>
                        <div className='card'>
                            <div className='card-body'>
                                Waaraan voldoet een correct CSV-bestand? Klik hier voor meer informatie over het opstellen en valideren van het CSV-bestand.
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
        : null
}

export default memo(Popover)
