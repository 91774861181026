import propTypes from 'prop-types'
import React, { memo } from 'react'


type PropType = {
    show: boolean,
    warningLocation: "results" | "conversion" | "conversionCsv"
}

/**
 * Informative and warning messages
 */
const WarningText: React.FC<PropType> = ({ show, warningLocation }) => {

      WarningText.propTypes = {
        show: propTypes.bool.isRequired,
        warningLocation: propTypes.oneOf(['results', 'conversion', 'conversionCsv'] as const).isRequired
      }


    const conversion = (
        <div className='warning form-container'>
            <p><b>Let op!</b></p>
            <p>
                De marktwaarde die wordt berekend in deze WOZ-bezitsvalidatie moet
                EXACT GELIJK ZIJN aan de marktwaarde zoals verantwoord op de balans(en).
            </p>
            <p>
                Download het XBRL-bestand pas nadat u heeft vastgesteld dat de berekende
                totalen op deze site gelijk zijn aan de marktwaarde zoals opgenomen
                in de balans(en).
            </p>
        </div>
    )

    const conversionCsv = (
        <div className='warning form-container'>
            <p><b>Let op!</b></p>
            <p>
                Warning text here.
            </p>
            <p>
                Some more warning text.
            </p>
        </div>
    )

    const results = (
        <div className='warning'>
            <p><b>Let op!</b></p>
            <p>
                De marktwaarde (per tak) die hier wordt getoond moet EXACT
                GELIJK ZIJN (op de euro) aan de marktwaarde (per tak) in uw balans.
            </p>
            <p>
                Download het XBRL-bestand voor het indienen van dVi2023 pas
                nadat u heeft vastgesteld dat de berekende totalen gelijk zijn.
                Als er een verschil tussen de marktwaarde in de WOZ en de marktwaarde
                in uw balans is, krijgt u een foutmelding tijdens het invoeren van
                dVi2023. In dit geval moet u opnieuw beginnen en verliest u de ingevoerde gegevens!
            </p>
        </div>
    )

    const text = {
        results: results,
        conversion: conversion,
        conversionCsv: conversionCsv
    }

    return show
        ? text[warningLocation]
        : null
}

export default memo(WarningText)
